// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minimalCardWrapper {
  width: 100%;
  border-radius: 16px;
  padding: 12px;
}
.minimalCardWrapper p {
  font-size: 16px;
  line-height: 24px;
}

.minimalCardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/PositionCard.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,mBAAA;EACA,aAAA;AADF;AAEE;EACE,eAAA;EACA,iBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AADF","sourcesContent":["@use 'styles/variables' as *;\n\n.minimalCardWrapper {\n  width: 100%;\n  border-radius: 16px;\n  padding: 12px;\n  & p {\n    font-size: 16px;\n    line-height: 24px;\n  }\n}\n\n.minimalCardRow {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
