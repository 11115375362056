// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2px;
  border: none;
  background: none;
  outline: none;
  border-radius: 36px;
}
.questionWrapper:hover, .questionWrapper:focus {
  opacity: 0.7;
}

.lightQuestionWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2px;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  width: 24px;
  height: 24px;
  color: white;
}
.lightQuestionWrapper:hover, .lightQuestionWrapper:focus {
  opacity: 0.7;
}

.questionMark {
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/QuestionHelper.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AADF;AAEE;EACE,YAAA;AAAJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AAAF;AACE;EACE,YAAA;AACJ;;AAEA;EACE,eAAA;AACF","sourcesContent":["@use 'styles/variables' as *;\n\n.questionWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0.2px;\n  border: none;\n  background: none;\n  outline: none;\n  border-radius: 36px;\n  &:hover, &:focus {\n    opacity: 0.7\n  }\n}\n.lightQuestionWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0.2px;\n  border: none;\n  background: none;\n  outline: none;\n  cursor: default;\n  border-radius: 36px;\n  width: 24px;\n  height: 24px;\n  color: white;\n  &:hover, &:focus {\n    opacity: 0.7\n  }\n}\n.questionMark {\n  font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
