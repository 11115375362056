// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.txTableFilterMobile {
  display: flex;
  align-items: center;
  position: absolute;
  top: -48px;
  right: 0;
}
.txTableFilterMobile > div {
  padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/TransactionsTable.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;AADF;AAEE;EACE,YAAA;AAAJ","sourcesContent":["@use 'styles/variables' as *;\n\n.txTableFilterMobile {\n  display: flex;\n  align-items: center;\n  position: absolute;\n  top: -48px;\n  right: 0;\n  & > div {\n    padding: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
