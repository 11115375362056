// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meldModalWrapper {
  max-width: 450px;
  background-color: rgb(12, 17, 42) !important;
  display: flex;
  flex-direction: column;
}

.meldModalClose {
  margin: 8px 8px 0 0;
  display: flex;
  justify-content: flex-end;
}

.buyFiatContent {
  flex: 1 1;
  overflow-y: auto;
}
.buyFiatContent iframe {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/MeldModal.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,4CAAA;EACA,aAAA;EACA,sBAAA;AAFF;;AAKA;EACE,mBAAA;EACA,aAAA;EACA,yBAAA;AAFF;;AAKA;EACE,SAAA;EACA,gBAAA;AAFF;AAGE;EACE,YAAA;AADJ","sourcesContent":["@use 'styles/variables' as *;\n@use 'styles/breakpoints' as *;\n\n.meldModalWrapper {\n  max-width: 450px;\n  background-color: rgb(12, 17, 42) !important;\n  display: flex;\n  flex-direction: column;\n}\n\n.meldModalClose {\n  margin: 8px 8px 0 0;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.buyFiatContent {\n  flex: 1;\n  overflow-y: auto;\n  & iframe {\n    border: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
