// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.currencyLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.currencyLogo img {
  width: 100%;
  height: 100%;
}
.currencyLogo img.ethereumLogo {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
}`, "",{"version":3,"sources":["webpack://./src/components/styles/CurrencyLogo.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AACI;EACE,6CAAA;AACN","sourcesContent":["@use 'styles/variables' as *;\n\n.currencyLogo {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  & img {\n    width: 100%;\n    height: 100%;\n    &.ethereumLogo {\n      box-shadow: 0px 6px 10px $grey13;\n    }  \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
