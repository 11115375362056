// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doubleCurrencyLogo {
  position: relative;
  display: flex;
}
.doubleCurrencyLogo > div:first-child {
  z-index: 2;
  margin-right: -4px;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/DoubleCurrencyLogo.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;AADF;AAEE;EACE,UAAA;EACA,kBAAA;AAAJ","sourcesContent":["@use 'styles/variables' as *;\n\n.doubleCurrencyLogo {\n  position: relative;\n  display: flex;\n  & > div:first-child {\n    z-index: 2;\n    margin-right: -4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
