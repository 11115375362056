// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.borderedCard {
  border: 1px solid rgba(130, 177, 255, 0.08);
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
}
.borderedCard:hover {
  border: 1px solid #c7cad9;
}

.poolFinderInfo {
  margin-top: 16px;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/PoolFinderModal.scss"],"names":[],"mappings":"AAEA;EACE,2CAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AADF;AAEE;EACE,yBAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;AADF","sourcesContent":["@use 'styles/variables' as *;\n\n.borderedCard {\n  border: 1px solid $divider;\n  padding: 8px;\n  border-radius: 10px;\n  cursor: pointer;\n  &:hover {\n    border: 1px solid $textPrimary;\n  }\n}\n\n.poolFinderInfo {\n  margin-top: 16px;\n  padding: 8px;\n  border-radius: 10px;\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
